(function () {
  'use strict';

  const formButtons = document.querySelectorAll('.request-proto');
  const formClose =  document.querySelector('.form-close');
  const formLayer = document.querySelector('.form-layer');
  const form = document.querySelector('.form');
  const formAction = document.querySelector('.form__actions');
  const formMessage = document.querySelector('.form-message');
  const cover = document.querySelector('.form-cover');

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  function handleDOMContentLoaded() {
    formButtons.forEach((button) => button.addEventListener('click', handleShowForm));
    formClose.addEventListener('click', handleHideForm);
    form.addEventListener('submit', handleFormSubmit);
  }

  function handleShowForm(e) {
    e.preventDefault();

    formLayer.classList.add('form-layer--visible');
    cover.classList.add('form-cover--visible');
  }

  function handleHideForm(e) {
    e.preventDefault();

    formLayer.classList.remove('form-layer--visible');
    cover.classList.remove('form-cover--visible');
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    formAction.firstElementChild.setAttribute('disabled', 'disabled');
    const formData = new FormData(form);
    fetch('/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams(formData).toString()
    })
      .then(() => {
        formAction.classList.add('form__actions--hidden');
        formMessage.innerHTML = `<p>Ricevuto! La tua richiesta &egrave; stata presa in carico: ti invieremo alla tua email le indicazioni per poter accedere a Missivery.</p>`;
        formMessage.classList.add('form-message--ok');
      })
      .catch((error) => {
        formAction.firstElementChild.removeAttribute('disabled');
        formMessage.innerHTML = `<p>Oops! Qualcosa è andato storto. Ti consigliamo di riprovare più tardi oppure di contattaci direttamente all'indirizzo <a href="mailto:info@missivery.it">info@missivery.it</a>.</p>`;
        formMessage.classList.add('form-message--no');
      });
  }
})();
